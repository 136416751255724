import * as React from 'react';
import { GoChevronDown, GoChevronUp } from 'react-icons/go';

import ActionableLink from './actionable-link';

const ICON_STYLE: React.CSSProperties = {
  width: '1.5em',
  height: '1.5em',
};

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
}

const Collapsible = ({ title, content }: Props) => {
  const [open, setOpen] = React.useState(false);
  const toggleCollapsible = React.useCallback(() => {
    setOpen(prev => !prev);
  }, []);

  return (
    <div className="collapsible">
      <div className="header">
        <ActionableLink
          action={toggleCollapsible}
          title={open ? 'Hide' : 'Read more'}
        >
          <div className="title">{title}</div>
          <span className="inline-flex flex-right align-items-center nowrap">
            <span className="hide-show">{open ? 'Hide' : 'Read more'}</span>
            {open ? (
              <GoChevronUp style={ICON_STYLE} />
            ) : (
              <GoChevronDown style={ICON_STYLE} />
            )}
          </span>
        </ActionableLink>
      </div>
      {open && content}
    </div>
  );
};

export default Collapsible;
