import axios, { AxiosError } from 'axios';
import * as React from 'react';
import * as SimplePeer from 'simple-peer';
import * as io from 'socket.io-client';

import { DEFAULT_PARTY_NAME } from '../../../shared/constants';
import { UserInfo } from '../../../shared/types';
import { API_URLS } from '../../../shared/urls';
import { COMMON_PEER_OPTIONS, MAX_VIDEO_SIZE } from '../../constants';
import { ModalCancelled } from '../../modal/open';
import { checkAndDisplayPolicy } from '../../policy';
import { InjectedRouteProps } from '../../router';
import { StoreContext } from '../../store';
import {
  displayError,
  displayWarning,
  getErrorString,
  runWithPrefixedErrors,
} from '../../utils';
import { promptUserName } from '../prompt-user-name';
import { Connections } from '../types';
import {
  constrainMediaConstraints,
  getVendorGetUserMedia,
  handlePeerErrorGuest,
  handlePeerErrorOwner,
  setBitRate,
} from '../utils';

type Props = InjectedRouteProps;

interface Party {
  id: string;
  setMyStream: (myStream: MediaStream) => void;
  setLoading: (loading: boolean) => void;
  setConnected: (connected: boolean) => void;
  setExists: (exists: undefined | boolean) => void;
  setConnections: (connections: Connections) => void;
  setNoUserMedia: (noUserMedia: boolean) => void;
  setVideoAndAudioDisabled: (noUserMedia: boolean) => void;
  setSocket: (socket: SocketIOClient.Socket) => void;
  setMyColor: (color: string) => void;
  connections: Connections;
}

const sdpTransform = (sdp: string): string =>
  setBitRate(setBitRate(sdp, 'video', 256), 'audio', 50);

const useConnections = (props: Props, party: Party) => {
  const socketRef = React.useRef<SocketIOClient.Socket>();
  const { myName, setState } = React.useContext(StoreContext);

  const destroyPeers = () => {
    Object.keys(party.connections).forEach(user => {
      party.connections[user]?.peer?.destroy();
    });
  };

  React.useEffect(() => {
    if (socketRef.current) {
      socketRef.current.emit('change-name', myName);
    }
  }, [myName]);

  React.useEffect(() => {
    let myStream: MediaStream | undefined;

    const connect = async () => {
      party.setLoading(true);
      party.setConnected(false);
      party.setExists(undefined);

      const getUserMedia = getVendorGetUserMedia();

      if (!getUserMedia) {
        party.setNoUserMedia(true);
        return;
      }

      const response = await runWithPrefixedErrors(
        () =>
          axios.request<{ exists: boolean; name?: string | null }>({
            method: 'GET',
            url: API_URLS.PARTY_CHECK.construct({ partyId: party.id }, {}),
            headers: {
              'Cache-Control': 'no-cache',
            },
          }),
        'Party check'
      );

      party.setLoading(false);

      if (!response.data.exists) {
        party.setExists(false);
      } else if (response.data.exists) {
        party.setExists(true);
      }

      myStream = new MediaStream();

      let video: MediaStream | undefined;
      let audio: MediaStream | undefined;

      try {
        video = await getUserMedia({
          video: constrainMediaConstraints({
            width: {
              max: MAX_VIDEO_SIZE,
            },
            height: {
              max: MAX_VIDEO_SIZE,
            },
            facingMode: 'user',
            frameRate: 30,
          }),
        });

        video.getVideoTracks().forEach(track => {
          myStream?.addTrack(track);
        });
      } catch (err) {
        const message = `Your video is not enabled - ${getErrorString(err)}`;
        displayWarning(message);
      }

      try {
        audio = await getUserMedia({
          audio: true,
        });

        audio.getAudioTracks().forEach(track => {
          myStream?.addTrack(track);
        });
      } catch (err) {
        const message = `Your audio is not enabled - ${getErrorString(err)}`;
        displayWarning(message);
      }

      if (!audio && !video) {
        party.setVideoAndAudioDisabled(true);
        return;
      }

      party.setMyStream(myStream);

      let myNewName = await promptUserName(
        `Welcome to ${response.data.name || DEFAULT_PARTY_NAME}`,
        myName
      );

      if (myNewName === ModalCancelled) {
        displayError('You managed to cancel entering your name, well done');
        return;
      }

      if (myNewName instanceof Error) {
        displayError(getErrorString(myNewName));
        return;
      }

      myNewName = myNewName.trim().substring(0, 100);

      setState({ myName: myNewName });

      const socket = io(props.location.pathname);

      party.setSocket(socket);
      socketRef.current = socket;

      socket.on('connect', () => {
        party.setConnected(true);
        socket.emit('change-name', myNewName);
        destroyPeers();
      });

      socket.on('disconnect', () => {
        party.setConnected(false);
      });

      socket.on('assign-color', (color: string) => {
        party.setMyColor(color);
      });

      socket.on('reload', () => {
        window.location.reload();
      });

      socket.on('signals-please', (users: ReadonlyArray<string>) => {
        users.forEach(user => {
          if (party.connections[user]?.peer) {
            party.connections[user]?.peer?.destroy();
          }

          const peer = new SimplePeer({
            ...COMMON_PEER_OPTIONS,
            initiator: true,
            trickle: false,
            stream: myStream,
            sdpTransform,
          });

          party.connections[user] = {
            ...party.connections[user],
            peer,
          };

          peer.once('signal', signal => {
            socket.emit('signal-for', user, signal);
          });

          peer.once('stream', stream => {
            party.connections[user] = {
              ...party.connections[user],
              stream,
            };

            party.setConnections({
              ...party.connections,
            });
          });

          peer.on('error', handlePeerErrorOwner);

          peer.once('close', () => {
            party.connections[user]?.peer?.destroy();
            delete party.connections[user];
            party.setConnections({
              ...party.connections,
            });
          });
        });
      });

      socket.on(
        'signal-from',
        (user: string, signal: SimplePeer.SignalData, userInfo: UserInfo) => {
          if (party.connections[user]?.peer) {
            party.connections[user]?.peer?.destroy();
          }

          const peer = new SimplePeer({
            ...COMMON_PEER_OPTIONS,
            trickle: false,
            stream: myStream,
            sdpTransform,
          });

          peer.signal(signal);

          party.connections[user] = {
            peer,
            ...userInfo,
          };

          peer.once('signal', sig => {
            socket.emit('offer-for', user, sig);
          });

          peer.once('stream', stream => {
            party.connections[user] = {
              ...party.connections[user],
              stream,
            };
            party.setConnections({
              ...party.connections,
            });
          });

          peer.on('error', handlePeerErrorGuest);

          peer.once('close', () => {
            party.connections[user]?.peer?.destroy();
            delete party.connections[user];
            party.setConnections({
              ...party.connections,
            });
          });
        }
      );

      socket.on(
        'offer-from',
        (user: string, signal: SimplePeer.SignalData, userInfo: UserInfo) => {
          party.connections[user] = {
            ...party.connections[user],
            ...userInfo,
          };

          party.connections[user]?.peer?.signal(signal);
          party.setConnections({
            ...party.connections,
          });
        }
      );

      socket.on('user-disconnect', (user: string) => {
        party.connections[user]?.peer?.destroy();
        delete party.connections[user];
        party.setConnections({
          ...party.connections,
        });
      });

      socket.on('change-name', (user: string, name: string | null) => {
        party.connections[user] = {
          ...party.connections[user],
          name,
        };
        party.setConnections({
          ...party.connections,
        });
      });
    };

    const doubleCheckPolicy = (): Promise<boolean> => {
      return checkAndDisplayPolicy().then(accepted => {
        if (accepted === false) {
          return doubleCheckPolicy();
        }

        return true;
      });
    };

    doubleCheckPolicy().then(() =>
      connect().catch((error: string | Error | AxiosError) => {
        displayError(getErrorString(error));
        party.setLoading(false);
        party.setConnected(false);
      })
    );

    return () => {
      if (socketRef.current) {
        socketRef.current.removeAllListeners();
        socketRef.current.close();
      }

      if (myStream) {
        myStream.getTracks().forEach(track => {
          track.stop();
        });
      }

      destroyPeers();
    };
  }, [party.id]);
};

export default useConnections;
