import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import {
  MATCHES_ANDROID,
  MATCHES_IOS,
  MATCHES_WINDOWS_PHONE,
} from './constants';

export const getErrorString = (error: string | Error | AxiosError) => {
  if (typeof error === 'string') {
    return error;
  }

  if ('isAxiosError' in error && error.isAxiosError) {
    if (error.response) {
      if (error.response.data) {
        return (
          error.response.data.message ||
          error.message ||
          error.code ||
          'Unknown error'
        );
      }

      return error.message || error.code || 'Unknown error';
    }
  }

  return error.message || error.toString() || 'Unknown error';
};

export const runWithPrefixedErrors = async <T extends any>(
  fn: () => Promise<T>,
  prefix: string
) => {
  try {
    return await fn();
  } catch (err) {
    throw new Error(`${prefix}: ${getErrorString(err)}`);
  }
};

export const isMobile = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (!userAgent) {
    return true;
  }

  const isWindowsPhone = MATCHES_WINDOWS_PHONE.test(userAgent);
  const isAndroid = MATCHES_ANDROID.test(userAgent);
  const isIOS = MATCHES_IOS.test(userAgent) && !window.MSStream;

  return isWindowsPhone || isAndroid || isIOS;
};

export const displayError = (error: string) => {
  toast.error(error);
};

export const displayWarning = (warning: string) => {
  toast.warn(warning);
};

export type Key = 'SPACE' | 'ENTER';

interface KeyLookup {
  keyCode: number;
  key: readonly string[];
}

const KEY_MAP: Record<Key, KeyLookup> = {
  SPACE: {
    keyCode: 32,
    key: ['Space', ' '],
  },
  ENTER: {
    keyCode: 13,
    key: ['Enter', 'Return'],
  },
};

export type KeyHandler<T extends HTMLElement> = (
  event: React.KeyboardEvent<T>
) => void;

export const handleKeys = <T extends HTMLElement>(
  event: React.KeyboardEvent<T>,
  handlers: Partial<Record<Key, KeyHandler<T>>>
) => {
  (Object.keys(handlers) as ReadonlyArray<Key>).forEach(key => {
    const handler = handlers[key];

    if (
      (event.keyCode === KEY_MAP[key].keyCode ||
        KEY_MAP[key].key.indexOf(event.key) >= 0) &&
      typeof handler === 'function'
    ) {
      handler(event);
    }
  });
};
