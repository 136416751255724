import * as React from 'react';
import {
  GoCommentDiscussion,
  GoMute,
  GoPencil,
  GoSignOut,
  GoUnmute,
} from 'react-icons/go';

import { CLIENT_URLS } from '../../shared/urls';
import ActionableLink from '../actionable-link';
import { CONTROL_BAR_HEIGHT } from '../constants';
import useDimensions from '../hooks/use-dimensions';
import Grid from '../icons/grid';
import Separate from '../icons/separate';
import { ModalCancelled } from '../modal/open';
import { InjectedRouteProps, Link } from '../router';
import { StoreContext } from '../store';
import { displayError, getErrorString } from '../utils';
import useConnections from './hooks/use-connections';
import Messages from './messages';
import { promptUserName } from './prompt-user-name';
import Status from './status';
import { Connections, Layout } from './types';
import { getVideoDimensions } from './utils';
import Video from './video';

type Props = InjectedRouteProps;

const CONTROL_STYLE: React.CSSProperties = {
  width: CONTROL_BAR_HEIGHT,
  height: CONTROL_BAR_HEIGHT,
};

const LEAVE_STYLE: React.CSSProperties = {
  transform: 'scale(-1, 1)',
};

const Party = (props: Props) => {
  const { muted, myName, layout, setState } = React.useContext(StoreContext);
  const [videoAndAudioDisabled, setVideoAndAudioDisabled] = React.useState(
    false
  );
  const [noUserMedia, setNoUserMedia] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [connected, setConnected] = React.useState(false);
  const [exists, setExists] = React.useState<boolean>();
  const [myColor, setMyColor] = React.useState<string>();
  const [myStream, setMyStream] = React.useState<MediaStream>();
  const [myElement, setMyElement] = React.useState<HTMLElement>();
  const [connections, setConnections] = React.useState<Connections>({});
  const [socket, setSocket] = React.useState<SocketIOClient.Socket>();
  const [unreadMessageCount, setUnreadMessageCount] = React.useState(0);
  const refConnections = React.useRef<Connections>({});

  const { partyId } = CLIENT_URLS.PARTY_GET.deconstruct(
    props.location.pathname
  ).urlParams;

  useConnections(props, {
    id: partyId,
    setMyStream,
    setLoading,
    setConnected,
    setExists,
    setConnections,
    setNoUserMedia,
    setVideoAndAudioDisabled,
    setSocket,
    setMyColor,
    connections: refConnections.current,
  });

  React.useEffect(() => {
    if (myStream) {
      myStream.getAudioTracks().forEach(track => {
        track.enabled = !muted;
      });
    }
  }, [myStream, muted]);

  const onClickMute = React.useCallback(() => {
    setState(({ muted: currentMuted }) => ({
      muted: !currentMuted,
    }));
  }, []);

  const onClickLayout = React.useCallback(() => {
    setState(({ layout: currentLayout }) => {
      if (currentLayout === Layout.SEPARATE) {
        return { layout: Layout.GRID };
      }

      return { layout: Layout.SEPARATE };
    });
  }, []);

  const onClickNameChange = React.useCallback(async () => {
    let myNewName = await promptUserName('Change your name', myName);

    if (myNewName === ModalCancelled) {
      displayError('You managed to cancel entering your name, well done');
      return;
    }

    if (myNewName instanceof Error) {
      displayError(getErrorString(myNewName));
      return;
    }

    myNewName = myNewName.trim().substring(0, 100);

    setState({ myName: myNewName });
  }, [myName]);

  const toggleMessages = React.useCallback(() => {
    setState(prevState => ({
      areMessagesOpen: !prevState.areMessagesOpen,
    }));
  }, []);

  const setExternalElement = React.useCallback(
    (userId: string | null, element: HTMLElement | undefined) => {
      if (userId === null) {
        setMyElement(element);
        return;
      }

      const connection = refConnections.current[userId];

      if (connection) {
        connection.externalElement = element;
        setConnections({ ...refConnections.current });
      }
    },
    []
  );

  const dimensions = useDimensions();

  const connectionIdsWithStreams = Object.keys(connections)
    .filter(id => Boolean(connections[id]?.stream))
    .sort((aId, bId) => {
      const a = connections[aId];
      const b = connections[bId];

      if (!a?.name) {
        return 1;
      }

      if (!b?.name) {
        return -1;
      }

      if (a.name.toLowerCase() === b.name.toLowerCase()) {
        return 0;
      }

      return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
    });

  const { videoWidth, clusterWidth } = getVideoDimensions({
    dimensions,
    connectionIdsWithStreams,
    connections,
    myStream,
    myElement,
    separate: layout === Layout.SEPARATE,
  });

  const smallestDimension = Math.min(dimensions.width, dimensions.height);

  const myVideo = myStream && (
    <Video
      name={myName}
      color={myColor}
      muted
      className="my-video"
      width={
        layout === Layout.GRID
          ? videoWidth
          : Math.min(Math.max(smallestDimension * 0.3, 100), 200)
      }
      separate={layout === Layout.SEPARATE}
      userId={null}
      stream={myStream}
      externalElement={myElement}
      setExternalElement={setExternalElement}
    />
  );

  const userCount = Object.keys(connections).length + 1;

  return (
    <div className="party" style={{ paddingBottom: CONTROL_BAR_HEIGHT }}>
      <div className="control-bar" style={{ height: CONTROL_BAR_HEIGHT }}>
        <ul>
          <li style={CONTROL_STYLE} className="flex-left">
            <Link href="/" title="Go to the home page">
              Leave
              <GoSignOut style={LEAVE_STYLE} />
            </Link>
          </li>
          <li style={CONTROL_STYLE}>
            <ActionableLink
              action={onClickMute}
              title={muted ? 'Unmute your audio' : 'Mute your audio'}
            >
              {muted ? 'Muted' : 'Unmuted'}
              {muted ? <GoMute /> : <GoUnmute />}
            </ActionableLink>
          </li>
          <li style={CONTROL_STYLE}>
            <ActionableLink action={onClickNameChange} title="Change your name">
              Name
              <GoPencil />
            </ActionableLink>
          </li>
          <li style={CONTROL_STYLE}>
            <ActionableLink
              action={onClickLayout}
              title={
                layout === Layout.SEPARATE
                  ? 'Display your video with the others'
                  : 'Display your video in the corner'
              }
            >
              Layout
              {layout === Layout.SEPARATE ? <Separate /> : <Grid />}
            </ActionableLink>
          </li>
          <li style={CONTROL_STYLE} className="flex-right">
            <ActionableLink action={toggleMessages} title="View/hide messages">
              Chat
              {Boolean(unreadMessageCount) && (
                <span className="unread-messages" />
              )}
              <GoCommentDiscussion />
            </ActionableLink>
          </li>
        </ul>
      </div>
      <div className="status">
        <Status
          loading={loading}
          connected={connected}
          exists={exists}
          noUserMedia={noUserMedia}
          videoAndAudioDisabled={videoAndAudioDisabled}
          userCount={userCount}
        />
      </div>
      <div className="video-cluster" style={{ width: clusterWidth }}>
        {connectionIdsWithStreams.map(id => (
          <Video
            name={connections[id]?.name}
            color={connections[id]?.color}
            key={id}
            width={videoWidth}
            userId={id}
            stream={connections[id]?.stream!}
            externalElement={connections[id]?.externalElement}
            setExternalElement={setExternalElement}
            separate={null}
          />
        ))}
        {myVideo}
      </div>
      <Messages
        socket={socket}
        setUnreadMessageCount={setUnreadMessageCount}
        connected={connected}
      />
    </div>
  );
};

export default React.memo(Party);
