import * as React from 'react';
import { FunctionComponent, MouseEvent } from 'react';

import { handleKeys } from '../utils';
import withRouter, { InjectedRouteProps } from './with-router';

interface LinkProps {
  href: string;
  title: string;
  style?: React.CSSProperties;
}

const Link: FunctionComponent<LinkProps & InjectedRouteProps> = props => {
  const onClick = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      props.history.push(props.href);
    },
    [props.href]
  );

  const onKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      const performNavigation = () => {
        event.preventDefault();
        props.history.push(props.href);
      };

      handleKeys(event, {
        ENTER: performNavigation,
        SPACE: performNavigation,
      });
    },
    [props.href]
  );

  return (
    <a
      href={props.href}
      onClick={onClick}
      title={props.title}
      onKeyDown={onKeyDown}
      style={props.style}
    >
      {props.children}
    </a>
  );
};

export default withRouter(Link);
