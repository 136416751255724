import * as React from 'react';
import { toast } from 'react-toastify';

import { DEFAULT_USER_NAME } from '../../shared/constants';
import { UserInfo } from '../../shared/types';
import { CONTROL_BAR_HEIGHT } from '../constants';
import HyperlinkText from '../hyperlink-text';
import { StoreContext } from '../store';
import { handleKeys } from '../utils';
import { focusOnMount } from './utils';

const MESSAGE_TOAST_ID = 'MESSAGE';

const AUTO_SCROLL_TOLERANCE = 100;

const MESSAGES_STYLE = {
  bottom: CONTROL_BAR_HEIGHT,
  height: `calc(100% - ${CONTROL_BAR_HEIGHT + 10}px)`,
};

interface Props {
  socket: SocketIOClient.Socket | undefined;
  setUnreadMessageCount: (setter: number | ((count: number) => number)) => void;
  connected: boolean;
}

interface MessageUser extends UserInfo {
  id: string;
}

interface Message {
  user: MessageUser;
  text: string;
}

const Messages = (props: Props) => {
  const { myName, areMessagesOpen, setState } = React.useContext(StoreContext);
  const lastMessageTime = React.useRef(0);
  const [messages, setMessages] = React.useState<ReadonlyArray<Message>>([]);
  const [textAreaValue, setTextAreaValue] = React.useState('');
  const scroller = React.useRef<HTMLDivElement | null>(null);

  const areMessagesOpenRef = React.useRef<boolean>(areMessagesOpen);
  areMessagesOpenRef.current = areMessagesOpen;

  const onTextAreaChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setTextAreaValue(event.currentTarget.value.substring(0, 500));
    },
    []
  );

  const onTextAreaKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      handleKeys(event, {
        ENTER: () => {
          if (!event.shiftKey) {
            event.preventDefault();
            const value = event.currentTarget.value.trim();
            const now = Date.now();

            if (props.socket && value && now >= lastMessageTime.current + 500) {
              setTextAreaValue('');
              props.socket.emit('message', value);
              lastMessageTime.current = now;
            }
          }
        },
      });
    },
    [props.socket, myName]
  );

  React.useEffect(() => {
    if (props.socket) {
      props.socket.on(
        'message',
        (from: string, text: string, userInfo: UserInfo) => {
          setMessages(prevMessages => [
            ...prevMessages,
            {
              user: {
                ...userInfo,
                id: from,
              },
              text,
            },
          ]);

          if (!areMessagesOpenRef.current) {
            props.setUnreadMessageCount(count => count + 1);

            const toastContent = (
              <div className="message">
                <span className="user-name" style={{ color: userInfo.color }}>
                  {userInfo.name || DEFAULT_USER_NAME}
                </span>
                <p>{text}</p>
              </div>
            );

            if (toast.isActive(MESSAGE_TOAST_ID)) {
              toast.update(MESSAGE_TOAST_ID, { render: toastContent });
            } else {
              toast(toastContent, {
                toastId: MESSAGE_TOAST_ID,
                hideProgressBar: true,
                closeButton: false,
                onClick: () => {
                  setState({
                    areMessagesOpen: true,
                  });
                },
              });
            }
          }
        }
      );
    }
  }, [props.socket]);

  React.useEffect(() => {
    if (areMessagesOpen) {
      props.setUnreadMessageCount(0);
      toast.dismiss(MESSAGE_TOAST_ID);

      if (scroller.current) {
        scroller.current.scrollTop = scroller.current.scrollHeight;
      }
    }
  }, [areMessagesOpen]);

  React.useEffect(() => {
    if (areMessagesOpen) {
      if (scroller.current) {
        if (
          scroller.current.scrollTop + scroller.current.clientHeight >=
          scroller.current.scrollHeight - AUTO_SCROLL_TOLERANCE
        ) {
          scroller.current.scrollTop = scroller.current.scrollHeight;
        }
      }
    }
  }, [areMessagesOpen, messages]);

  if (!areMessagesOpen) {
    return null;
  }

  return (
    <div className="messages" style={MESSAGES_STYLE}>
      <div className="history">
        <div ref={scroller} className="scroller">
          {messages.map((message, index) => (
            <div key={`${index}-${message.user.id}`} className="message">
              <div className="user-name" style={{ color: message.user.color }}>
                {message.user.name || DEFAULT_USER_NAME}
              </div>
              <p>
                <HyperlinkText>{message.text}</HyperlinkText>
              </p>
            </div>
          ))}
        </div>
      </div>
      {!props.connected && (
        <div className="disconnected">Chat disconnected</div>
      )}
      <textarea
        disabled={!props.connected}
        placeholder="Enter a message"
        value={textAreaValue}
        onChange={onTextAreaChange}
        onKeyDown={onTextAreaKeyDown}
        ref={focusOnMount}
      ></textarea>
    </div>
  );
};

export default Messages;
