import * as React from 'react';
import { GoX } from 'react-icons/go';

import ActionableLink from './actionable-link';

const noop = () => null;

const ToastCloseButton = ({
  closeToast = noop,
}: {
  closeToast?: () => void;
}) => (
  <ActionableLink action={closeToast} title="Close">
    <GoX />
  </ActionableLink>
);

export default ToastCloseButton;
