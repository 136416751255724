import * as React from 'react';

import { focusOnMount } from '../party/utils';
import { ModalCancelled, ModalComponentProps } from './open';
import modalStore from './store';

interface PromptOptions {
  title: string;
  label: string;
  value: string | undefined | null;
  cancellable: boolean;
}

interface PromptProps extends PromptOptions, ModalComponentProps<string> {}

const Prompt = (props: PromptProps) => {
  const [value, setValue] = React.useState<string>(props.value || '');
  const onInputChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value);
    },
    []
  );

  const onClickCancel = React.useCallback(() => {
    modalStore.closeLastModal();
    props.reject(ModalCancelled);
  }, []);

  const onSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      modalStore.closeLastModal();
      props.resolve(value);
    },
    [value]
  );

  return (
    <form onSubmit={onSubmit}>
      <h1>{props.title}</h1>
      <div className="form-group">
        <label htmlFor="prompt-input">{props.label}</label>
        <input
          id="prompt-input"
          type="text"
          value={value}
          onChange={onInputChange}
          ref={focusOnMount}
        />
      </div>
      <div className="modal-footer">
        {props.cancellable && (
          <button type="button" onClick={onClickCancel} className="cancel">
            Cancel
          </button>
        )}
        <button type="submit">Okay</button>
      </div>
    </form>
  );
};

const createPrompt = (options: PromptOptions) => {
  return (props: ModalComponentProps<string>) => (
    <Prompt {...options} {...props} />
  );
};

export default createPrompt;
