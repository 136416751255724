import * as jsCookie from 'js-cookie';
import * as React from 'react';

import { APP_NAME } from '../shared/constants';
import Collapsible from './collapsible';
import openModal, {
  ModalCancelled,
  ModalComponentProps,
  ModalSize,
} from './modal/open';
import { displayError, getErrorString } from './utils';

const POLICY_KEY = 'accepted-policy-1';

export const acceptPolicy = () => {
  if (
    window.localStorage &&
    // tslint:disable-next-line:strict-type-predicates
    typeof window.localStorage.setItem === 'function'
  ) {
    window.localStorage.setItem(POLICY_KEY, 'true');
  } else {
    jsCookie.set(POLICY_KEY, 'true');
  }
};

export const hasAcceptedPolicy = () => {
  if (
    window.localStorage &&
    // tslint:disable-next-line:strict-type-predicates
    typeof window.localStorage.getItem === 'function'
  ) {
    return window.localStorage.getItem(POLICY_KEY) === 'true';
  } else {
    return jsCookie.get(POLICY_KEY) === 'true';
  }
};

const Policy = (props: ModalComponentProps<never>) => {
  const [accepted, setAccepted] = React.useState(hasAcceptedPolicy());
  const onCheckboxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAccepted(event.currentTarget.checked);
    },
    []
  );

  return (
    <>
      <h1 className="text-align-center">Welcome to {APP_NAME}!</h1>

      <Collapsible
        title={<h2>About the app</h2>}
        content={
          <>
            <p>
              Firstly, sorry this ended up a little longer than I intended, but
              I think you should know a bit about the app and your privacy.
            </p>

            <p>
              I (Jake) created this little app so that my friends and I could
              easily catch up and play games during the COVID-19 outbreak, but
              I'm more than happy for anyone to use this themselves.
            </p>

            <p>
              This is free for anyone to use, and I encourage you to use it if
              you don't want to have to pay for a video chat service, or if
              other services are failing to offer you the ease or quality you
              deserve.
            </p>

            <p>
              This should work with the latest version of Chrome, Firefox,
              Safari (and Edge I suppose) on desktop/laptop computers, and
              Android devices. It should also work with Safari on
              iPhone/iPad/iPod, but will not work with other browsers on those
              devices.
            </p>
          </>
        }
      />

      <Collapsible
        title={<h2>Cookies and privacy</h2>}
        content={
          <>
            <p>
              I don't want your data. Nothing is stored permanently. After you
              create a party any information you enter will only be stored for
              as long as that party is active. Once everyone has left the party
              it will automatically become inactive after about 5 minutes, and
              all your data will be removed. Next time you want to party just
              create a new one.
            </p>

            <p>
              Any information you enter will only be shared with people that
              have access to that party. You should not share the link to your
              party publicly. I will not share your information with any person
              or company. Information you enter will also be stored in your
              browser so, for example, you don't have to type out your name
              every time you use the app.
            </p>

            <p>
              Although parties are given a unique ID that is almost impossible
              to guess (
              <a
                href="https://en.wikipedia.org/wiki/Universally_unique_identifier#Collisions"
                target="_blank"
                title="Read about UUID collisions on Wikipedia"
              >
                about 1 in a billion
              </a>
              ) I cannot guarantee that unintended people will not be able to
              access your party. If someone you don't know gets access to your
              party you should leave and create a new one. You must completely
              close the window/tab in order to ensure you have left a party.
            </p>

            <p>
              I use{' '}
              <a
                href="https://en.wikipedia.org/wiki/Google_Analytics"
                target="_blank"
                title="Read about Google Analytics on Wikipedia"
              >
                Google Analytics
              </a>{' '}
              to track how many people are using the app so that I can ensure
              the server doesn't explode. None of your personal data is shared
              with Google. IP addresses are anonymized. You may be given a few
              cookies, but no advertising cookies.
            </p>
          </>
        }
      />

      <Collapsible
        title={<h2>Donate to keep the servers alive</h2>}
        content={
          <>
            <p>
              This app doesn't cost me a huge amount to run at the moment, but
              if a lot of people start using it then it may get quite pricey for
              me. If you'd like to help keep the servers running, or just want
              to buy me a drink to say thanks, you can{' '}
              <a
                href="https://www.paypal.me/jakesidsmith"
                target="_blank"
                title="Donate with PayPal"
              >
                donate here
              </a>{' '}
              (leave me a nice note).
            </p>
          </>
        }
      />

      <div className="modal-footer">
        <div className="form-group inline">
          <input
            id="accepted"
            type="checkbox"
            onChange={onCheckboxChange}
            checked={accepted}
            value={`${accepted}`}
          />
          <label htmlFor="accepted">Yeah, yeah, that's okay, whatever</label>
        </div>
        <button disabled={!accepted} type="button" onClick={props.resolve}>
          Okay
        </button>
      </div>
    </>
  );
};

export const checkAndDisplayPolicy = async (): Promise<boolean> => {
  if (!hasAcceptedPolicy()) {
    return openModal(Policy, ModalSize.MEDIUM)
      .then(() => {
        acceptPolicy();
        return true;
      })
      .catch(error => {
        if (error !== ModalCancelled) {
          displayError(getErrorString(error));
        }
        return false;
      });
  }

  return true;
};

export default Policy;
