import * as React from 'react';

const Grid = () => (
  <svg viewBox="0 0 256 256" className="icon">
    <g fill="currentColor">
      <path d="M32 158h80V98H32v60zM22 96c0-4.418 3.589-8 7.993-8h84.014A7.995 7.995 0 01122 96v64c0 4.418-3.589 8-7.993 8H29.993A7.995 7.995 0 0122 160V96zm122 62h80V98h-80v60zm-10-62c0-4.418 3.589-8 7.993-8h84.014A7.995 7.995 0 01234 96v64c0 4.418-3.589 8-7.993 8h-84.014a7.995 7.995 0 01-7.993-8V96z" />
      <path d="M10 246h236V10H10v236zM0 7.995A7.997 7.997 0 017.995 0h240.01A7.997 7.997 0 01256 7.995v240.01a7.997 7.997 0 01-7.995 7.995H7.995A7.997 7.997 0 010 248.005V7.995z" />
    </g>
  </svg>
);

export default Grid;
