import * as classNames from 'classnames';
import * as React from 'react';
import { MouseEvent } from 'react';
import * as ReactDOM from 'react-dom';
import { GoBrowser } from 'react-icons/go';

import { DEFAULT_USER_NAME } from '../../shared/constants';
import { CLIENT_URLS } from '../../shared/urls';
import ActionableLink from '../actionable-link';
import { CONTROL_BAR_HEIGHT, VIDEO_ASPECT } from '../constants';
import { isMobile } from '../utils';

const WINDOW_ICON_STYLE: React.CSSProperties = {
  display: 'block',
  width: '1.5em',
  height: '1.5em',
};

const deviceIsMobile = isMobile();

const PLAY_ERROR_MESSAGE =
  'It looks like your browser has automatically blocked this feed. Click here to resume';

interface Props {
  className?: string;
  muted?: boolean;
  stream: MediaStream;
  userId: string | null;
  externalElement: HTMLElement | undefined;
  setExternalElement: (
    userId: string | null,
    element: HTMLElement | undefined
  ) => void;
  width: number;
  separate: boolean | null;
  name: string | null | undefined;
  color: string | undefined;
}

const Video = (props: Props) => {
  const [playError, setPlayError] = React.useState(false);
  const interval = React.useRef<number>();
  const videoElement = React.useRef<HTMLVideoElement | null>(null);
  const onClickVideoError = React.useCallback(
    (event: MouseEvent) => {
      event.preventDefault();

      if (props.stream) {
        videoElement.current
          ?.play()
          .then(() => {
            setPlayError(false);
          })
          .catch(() => {
            setPlayError(true);
          });
      }
    },
    [props.stream]
  );
  const setStream = React.useCallback(
    (element: HTMLVideoElement | null) => {
      videoElement.current = element;

      if (element) {
        element.srcObject = props.stream;

        if (props.stream) {
          element
            .play()
            .then(() => {
              setPlayError(false);
            })
            .catch(() => {
              setPlayError(true);
            });
        }
      }
    },
    [props.stream]
  );

  React.useEffect(
    () => () => {
      window.clearInterval(interval.current);
    },
    []
  );

  const openInNewWindow = React.useCallback(() => {
    const external = window.open(
      CLIENT_URLS.POP_OUT,
      '',
      'toolbar=0,status=0,width=640,height=480'
    );
    if (external) {
      interval.current = window.setInterval(() => {
        if (external.closed) {
          props.setExternalElement(props.userId, undefined);
          window.clearInterval(interval.current);
        }
      }, 100);

      external.onload = () => {
        const app = external.document.getElementById('app');

        if (app) {
          props.setExternalElement(props.userId, app);
        } else {
          external.close();
        }
      };
    }
  }, []);

  const video = (
    <video
      muted={props.muted}
      ref={setStream}
      playsInline
      onClick={onClickVideoError}
      width={props.externalElement ? undefined : props.width - 4}
    />
  );

  const playErrorMessage = playError && (
    <div className="error-container" onClick={onClickVideoError}>
      <p className="error">{PLAY_ERROR_MESSAGE}</p>
    </div>
  );

  if (props.externalElement) {
    return ReactDOM.createPortal(
      <div className="full-screen">
        {playErrorMessage}
        {video}
      </div>,
      props.externalElement
    );
  }

  return (
    <div
      className={classNames('video-spacing', props.className, {
        separate: props.separate,
      })}
      style={{
        width: props.width,
        height: Math.floor(props.width * VIDEO_ASPECT),
        bottom: props.separate ? CONTROL_BAR_HEIGHT : undefined,
      }}
    >
      <div className="video-container" style={{ borderColor: props.color }}>
        {playErrorMessage}
        {video}
        {!props.separate && !props.externalElement && (
          <span className="user-name" style={{ color: props.color }}>
            {props.name || DEFAULT_USER_NAME}
          </span>
        )}
        {!deviceIsMobile && !playError && (
          <div className="controls">
            <ActionableLink
              action={openInNewWindow}
              title="Open in separate window"
            >
              <GoBrowser style={WINDOW_ICON_STYLE} />
            </ActionableLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Video);
