import * as React from 'react';

const LegacyRoom = () => (
  <div className="not-found">
    <p>Rooms have turned into parties.</p>
    <p>
      Go back to the{' '}
      <a href="/" title="Go to the home page">
        home page
      </a>{' '}
      to host a new party.
    </p>
    <p>If you keep seeing this page try clearing your browsers cache.</p>
  </div>
);

export default React.memo(LegacyRoom);
