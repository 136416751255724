import * as SimplePeer from 'simple-peer';

import { UserInfo } from '../../shared/types';

export interface Connection extends Partial<UserInfo> {
  peer?: SimplePeer;
  stream?: MediaStream;
  externalElement?: HTMLElement;
}

export interface Connections {
  [i: string]: Connection | undefined;
}

export interface Dimensions {
  width: number;
  height: number;
}

export enum Layout {
  GRID,
  SEPARATE,
}
