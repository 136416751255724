import * as pathToRegExp from 'path-to-regexp';
import * as React from 'react';
import { ReactElement } from 'react';

import { RouteProps } from './route';
import withRouter, { InjectedRouteProps } from './with-router';

interface OwnProps {
  children: ReadonlyArray<ReactElement<RouteProps>>;
}

type SwitchProps = InjectedRouteProps & OwnProps;

const Switch = (props: SwitchProps) => {
  return (
    props.children.find(child =>
      pathToRegExp.match(child.props.path)(props.location.pathname)
    ) ?? <noscript />
  );
};

export default withRouter(Switch);
