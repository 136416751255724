import * as React from 'react';

import { MATCHES_BEFORE_URLS, MATCHES_URL } from './constants';

interface Props {
  children: React.ReactNode;
}

const HyperlinkText = (props: Props) => {
  if (typeof props.children !== 'string') {
    return null;
  }

  const parts = props.children
    .split(MATCHES_BEFORE_URLS)
    .reduce((memo, part) => {
      const match = MATCHES_URL.exec(part);

      if (!match) {
        return [...memo, part];
      }

      const url = match[0];

      return [...memo, url, part.replace(MATCHES_URL, '')];
    }, [] as readonly string[]);

  return (
    <>
      {parts.map((part, index) => {
        const key = `${index}-${part}`;

        return MATCHES_URL.test(part) ? (
          <a key={key} href={part} target="_blank">
            {part}
          </a>
        ) : (
          <span key={key}>{part}</span>
        );
      })}
    </>
  );
};

export default React.memo(HyperlinkText);
