import axios, { AxiosError } from 'axios';
import * as React from 'react';
import { GoSignIn } from 'react-icons/go';

import { API_URLS, CLIENT_URLS } from '../shared/urls';
import ActionableLink from './actionable-link';
import openModal, { ModalCancelled, ModalSize } from './modal/open';
import createPrompt from './modal/prompt';
import Policy, { checkAndDisplayPolicy } from './policy';
import { InjectedRouteProps } from './router';
import { StoreContext } from './store';
import { displayError, getErrorString } from './utils';

const HOST_ICON_STYLE: React.CSSProperties = {
  width: '2em',
  height: '2em',
  marginLeft: 10,
  marginBottom: -3,
  marginRight: -5,
  transform: 'scale(-1, 1)',
};

const Home = (props: InjectedRouteProps) => {
  const { lastPartyName, setState } = React.useContext(StoreContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    checkAndDisplayPolicy();
  }, []);

  const openPolicy = React.useCallback(() => {
    openModal(Policy, ModalSize.MEDIUM).catch(error => {
      if (error !== ModalCancelled) {
        displayError(getErrorString(error));
      }
    });
  }, []);

  const onClick = React.useCallback(async () => {
    let partyName = await openModal(
      createPrompt({
        title: 'Party name',
        label: 'Enter a name for your party',
        value: lastPartyName,
        cancellable: true,
      })
    ).catch((error: string | Error | typeof ModalCancelled) => {
      if (error === ModalCancelled) {
        return error;
      }

      return new Error(getErrorString(error));
    });

    if (partyName === ModalCancelled) {
      return;
    }

    if (partyName instanceof Error) {
      displayError(getErrorString(partyName));
      return;
    }

    partyName = partyName.trim().substring(0, 100);

    setState({ lastPartyName: partyName });

    axios
      .request<{ partyId: string }>({
        method: 'POST',
        url: API_URLS.PARTY_CREATE,
        data: {
          name: partyName,
        },
      })
      .then(response => {
        const { partyId } = response.data;
        props.history.push(CLIENT_URLS.PARTY_GET.construct({ partyId }, {}));
      })
      .catch((error: string | Error | AxiosError) => {
        displayError(`Party creation: ${getErrorString(error)}`);
        setLoading(false);
      });
  }, []);

  return (
    <div className="home text-align-center">
      <span>
        <button type="button" onClick={onClick}>
          Host a party
          <GoSignIn style={HOST_ICON_STYLE} />
        </button>
        <p>
          <ActionableLink
            action={openPolicy}
            title="View About, Privacy/Cookie Policy, Donate"
          >
            About, Privacy/Cookie Policy, Donate
          </ActionableLink>
        </p>
        {loading && <p>Loading...</p>}
      </span>
    </div>
  );
};

export default React.memo(Home);
