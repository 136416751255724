import * as React from 'react';

const NotFound = () => (
  <div className="not-found">
    <p>This page doesn't exist...</p>
    <p>How did you get here?</p>
    <p>Are you just typing in random stuff into the address bar?</p>
    <p>Maybe someone sent you a bad link...</p>
    <p>...yeah, it's probably their fault.</p>
  </div>
);

export default React.memo(NotFound);
