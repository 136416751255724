import openModal, { ModalCancelled } from '../modal/open';
import createPrompt from '../modal/prompt';
import { getErrorString } from '../utils';

export const promptUserName = (
  title: string,
  value: string | null | undefined
) =>
  openModal(
    createPrompt({
      title,
      label: 'Enter your name so people know who you are',
      value,
      cancellable: false,
    })
  ).catch((error: string | Error | typeof ModalCancelled) => {
    if (error === ModalCancelled) {
      return error;
    }

    return new Error(getErrorString(error));
  });
