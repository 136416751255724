import * as SimplePeer from 'simple-peer';

import {
  CONTROL_BAR_HEIGHT,
  MATCHES_NEWLINE,
  VIDEO_ASPECT,
} from '../constants';
import { displayError, getErrorString } from '../utils';
import { Connections, Dimensions } from './types';

interface Props {
  dimensions: Dimensions;
  connectionIdsWithStreams: ReadonlyArray<string>;
  connections: Connections;
  myStream: MediaStream | undefined;
  myElement: HTMLElement | undefined;
  separate: boolean;
}

const getVideoWidth = (
  partyWidth: number,
  partyHeight: number,
  renderedCount: number,
  columnCount: number
) => {
  const rowCount = Math.ceil(renderedCount / columnCount);

  const possibleVideoWidth = Math.floor(
    columnCount !== 0 ? partyWidth / columnCount : partyWidth
  );

  const wouldOverflowHeight =
    possibleVideoWidth * VIDEO_ASPECT * rowCount > partyHeight;

  return wouldOverflowHeight
    ? Math.floor((partyHeight / rowCount) * (1 / VIDEO_ASPECT))
    : possibleVideoWidth;
};

export const getVideoDimensions = ({
  dimensions,
  connectionIdsWithStreams,
  connections,
  myStream,
  myElement,
  separate,
}: Props) => {
  const partyWidth = dimensions.width;
  const partyHeight = dimensions.height - CONTROL_BAR_HEIGHT;

  const renderedCount =
    connectionIdsWithStreams.filter(id => {
      const connection = connections[id];
      return !Boolean(connection && connection.externalElement);
    }).length + (myStream ? (myElement || separate ? 0 : 1) : 0);

  if (!renderedCount) {
    return {
      videoWidth: 0,
      clusterWidth: 0,
    };
  }

  const columns = [...Array(renderedCount)].map((_, index) => index + 1);

  const optimalWidth = columns
    .map(columnCount => {
      const videoWidth = getVideoWidth(
        partyWidth,
        partyHeight,
        renderedCount,
        columnCount
      );
      const clusterWidth = videoWidth * columnCount;

      return {
        videoWidth,
        clusterWidth,
      };
    })
    .reduce(
      (memo, optimal) => {
        if (optimal.videoWidth > memo.videoWidth) {
          return optimal;
        }

        return memo;
      },
      { videoWidth: 0, clusterWidth: 0 }
    );

  return optimalWidth;
};

export const getVendorGetUserMedia = () => {
  if (
    navigator.mediaDevices &&
    // tslint:disable-next-line:strict-type-predicates
    typeof navigator.mediaDevices.getUserMedia === 'function'
  ) {
    return (constraints: MediaStreamConstraints = {}) =>
      navigator.mediaDevices.getUserMedia.call(
        navigator.mediaDevices,
        constraints
      );
  }

  const vendorGetUserMedia =
    navigator.getUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.webkitGetUserMedia;

  if (!vendorGetUserMedia) {
    return null;
  }

  return (constraints: MediaStreamConstraints = {}) =>
    new Promise<MediaStream>((resolve, reject) => {
      vendorGetUserMedia.call(
        navigator,
        constraints,
        stream => {
          resolve(stream);
        },
        error => {
          reject(error);
        }
      );
    });
};

export const constrainMediaConstraints = (
  constraints: MediaTrackConstraints
) => {
  const supported = navigator.mediaDevices.getSupportedConstraints
    ? navigator.mediaDevices.getSupportedConstraints()
    : { width: true, height: true };

  const final: MediaTrackConstraints = {};

  for (const key in constraints) {
    if (Object.prototype.hasOwnProperty.call(constraints, key)) {
      if ((supported as any)[key]) {
        (final as any)[key] = (constraints as any)[key];
      }
    }
  }

  return final;
};

export const focusOnMount = (
  element: HTMLInputElement | HTMLTextAreaElement | null
) => {
  if (element) {
    element.focus();
  }
};

const createPeerErrorHandler = (user: string) => (
  err: SimplePeer.PeerError
) => {
  if (
    err?.code === 'ERR_CONNECTION_FAILURE' ||
    err?.code === 'ERR_DATA_CHANNEL'
  ) {
    return;
  }

  displayError(`Peer error (${user}): ${getErrorString(err)}`);
};

export const handlePeerErrorOwner = createPeerErrorHandler('owner');
export const handlePeerErrorGuest = createPeerErrorHandler('guest');

export const setBitRate = (
  sdp: string,
  media: string,
  bitRate: number
): string => {
  const lines = sdp.split(MATCHES_NEWLINE);

  const mMatchIndex = lines.findIndex(line => line.indexOf(`m=${media}`) === 0);

  if (mMatchIndex < 0) {
    return sdp;
  }

  const mNextIndex = lines.findIndex(
    (line, index) => index > mMatchIndex && line.indexOf('m=') === 0
  );

  const bASIndex = lines.findIndex(
    (line, index) =>
      index > mMatchIndex &&
      (mNextIndex === -1 || index < mNextIndex) &&
      line.indexOf('b=AS') === 0
  );

  if (bASIndex >= 0) {
    lines.splice(bASIndex, 1, `b=AS:${bitRate}`);
  } else {
    lines.splice(mMatchIndex + 1, 0, `b=AS:${bitRate}`);
  }

  return lines.join('\n');
};
