export const COMMON_PEER_OPTIONS = {
  offerOptions: {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
  },
};

export const VIDEO_ASPECT = 0.8;
export const MAX_VIDEO_SIZE = 640;
export const CONTROL_BAR_HEIGHT = 54;

export const MATCHES_WINDOWS_PHONE = /windows phone/i;
export const MATCHES_ANDROID = /android/i;
export const MATCHES_IOS = /iPad|iPhone|iPod/;
export const MATCHES_NEWLINE = /\r\n|\n|\r/g;
export const MATCHES_URL = /https?:\/\/\S+\.[a-z]{1,10}(?:\S+[\w\/]|\/)?/;
export const MATCHES_BEFORE_URLS = /(?=https?:\/\/\S+\.[a-z]{1,10}(?:\S+[\w\/]|\/)?)/;
