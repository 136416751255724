import * as React from 'react';

interface Props {
  loading: boolean;
  connected: boolean;
  exists: boolean | undefined;
  noUserMedia: boolean;
  videoAndAudioDisabled: boolean;
  userCount: number;
}

const Status = ({
  loading,
  connected,
  exists,
  noUserMedia,
  videoAndAudioDisabled,
  userCount,
}: Props) => {
  if (noUserMedia) {
    return (
      <>
        <p className="error">
          This browser does not support video or audio sharing.
        </p>
        <p className="warning">
          If you are on an iPad, iPhone, or iPod, please try Safari (Apple do
          not allow other browsers access).
        </p>
        <p className="warning">
          If you are on a desktop computer, laptop, or Android mobile/tablet
          please make sure you have the latest version of Chrome, Firefox, or
          Safari (or Edge I suppose).
        </p>
      </>
    );
  }

  if (videoAndAudioDisabled) {
    return (
      <>
        <p className="error">
          No audio or video enabled. You must enable at least one audio or video
          source.
        </p>
      </>
    );
  }

  if (loading) {
    return <p>Checking party...</p>;
  }

  if (exists === false) {
    return <p className="error">This party has ended</p>;
  }

  if (!connected) {
    return <p>Waiting for connection...</p>;
  }

  return (
    <p>
      Connected ({userCount} user{userCount > 1 ? 's' : ''})
    </p>
  );
};

export default React.memo(Status);
