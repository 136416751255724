import createTSURL, { requiredString } from '@jakesidsmith/tsurl';

export const API_URLS = {
  PARTY_CREATE: '/api/party',
  PARTY_CHECK: createTSURL(['/api/party', requiredString('partyId')], {
    trailingSlash: false,
  }),
};

export const CLIENT_URLS = {
  PARTY_GET: createTSURL(['/party', requiredString('partyId')], {
    trailingSlash: false,
  }),
  LEGACY_ROOM_GET: createTSURL(['/room', requiredString('roomId')], {
    trailingSlash: false,
  }),
  POP_OUT: '/pop-out',
};
