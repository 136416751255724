import * as React from 'react';

const Separate = () => (
  <svg viewBox="0 0 256 256" className="icon">
    <g fill="currentColor">
      <path d="M38 198h180V58H38v140zM28 56.003C28 51.583 31.578 48 35.995 48h184.01c4.416 0 7.995 3.585 7.995 8.003v143.994c0 4.42-3.578 8.003-7.995 8.003H35.995c-4.416 0-7.995-3.585-7.995-8.003V56.003z" />
      <path d="M178 198h40v-28h-40v28zm-10-29.993c0-4.422 3.574-8.007 7.999-8.007H220c4.418 0 7.999 3.588 7.999 8.007v31.986c0 4.422-3.574 8.007-7.999 8.007H176c-4.418 0-7.999-3.588-7.999-8.007v-31.986z" />
      <path d="M10 246h236V10H10v236zM0 7.995A7.997 7.997 0 017.995 0h240.01A7.997 7.997 0 01256 7.995v240.01a7.997 7.997 0 01-7.995 7.995H7.995A7.997 7.997 0 010 248.005V7.995z" />
    </g>
  </svg>
);

export default Separate;
