import * as React from 'react';
import { ToastContainer } from 'react-toastify';

import { CLIENT_URLS } from '../shared/urls';
import Home from './home';
import LegacyRoom from './legacy-room';
import ModalRenderer from './modal/renderer';
import NotFound from './not-found';
import Party from './party';
import { History, Route, Router, Switch } from './router';
import { StoreProvider } from './store';
import ToastCloseButton from './toast-close-button';

const history = new History();

const App = () => (
  <StoreProvider>
    <Router history={history}>
      <Switch>
        <Route path="/" component={Home} />
        <Route
          path={CLIENT_URLS.PARTY_GET.getURLTemplate()}
          component={Party}
        />
        <Route
          path={CLIENT_URLS.LEGACY_ROOM_GET.getURLTemplate()}
          component={LegacyRoom}
        />
        <Route path="/:any*" component={NotFound} />
      </Switch>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      closeButton={<ToastCloseButton />}
    />
    <ModalRenderer />
  </StoreProvider>
);

export default React.memo(App);
