import * as React from 'react';

import { Layout } from './party/types';
import { displayError, getErrorString } from './utils';

const LOCAL_STORAGE_KEY = 'state';

const setLocalStorage = (value: Partial<StoreState>) => {
  if (
    window.localStorage &&
    // tslint:disable-next-line:strict-type-predicates
    typeof window.localStorage.setItem === 'function'
  ) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value || {}));
  }
};

const getLocalStorage = (): Partial<StoreState> => {
  if (
    window.localStorage &&
    // tslint:disable-next-line:strict-type-predicates
    typeof window.localStorage.setItem === 'function'
  ) {
    try {
      return (
        JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_KEY) || '{}') || {}
      );
    } catch (err) {
      displayError(getErrorString(err));
      return {};
    }
  }

  return {};
};

export interface StoreState {
  layout: Layout;
  myName?: string | null;
  lastPartyName?: string | null;
  setState: Store['setState'];
  areMessagesOpen: boolean;
  muted: boolean;
}

export type Subscriber = (state: Readonly<StoreState>) => void;

class Store {
  private subscribers: Subscriber[] = [];
  private state: Readonly<StoreState>;

  public constructor() {
    this.state = {
      layout: Layout.SEPARATE,
      areMessagesOpen: false,
      muted: false,
      ...getLocalStorage(),
      setState: this.setState,
    };
  }

  public setState = (
    state:
      | Omit<Partial<StoreState>, 'setState'>
      | ((
          prevState: Omit<StoreState, 'setState'>
        ) => Omit<Partial<StoreState>, 'setState'>)
  ) => {
    if (typeof state === 'function') {
      this.state = { ...this.state, ...state(this.state) };
    } else {
      this.state = { ...this.state, ...state };
    }

    setLocalStorage(this.state);
    this.emitChange();
  };

  public getState = (): StoreState => {
    return this.state;
  };

  public subscribe = (subscriber: Subscriber) => {
    if (this.subscribers.indexOf(subscriber) < 0) {
      this.subscribers.push(subscriber);
      subscriber(this.state);
    }

    const unsubscribe = () => {
      const index = this.subscribers.indexOf(subscriber);

      if (index >= 0) {
        this.subscribers.splice(index, 1);
      }
    };

    return unsubscribe;
  };

  private emitChange() {
    this.subscribers.forEach(subscriber => {
      subscriber(this.state);
    });
  }
}

const store = new Store();

const StoreContext = React.createContext<StoreState>({
  ...store.getState(),
});

const StoreProvider: React.FC = props => {
  const [context, setContext] = React.useState<StoreState>({
    ...store.getState(),
  });

  React.useEffect(() => {
    return store.subscribe(state => {
      setContext(state);
    });
  }, []);

  return (
    <StoreContext.Provider value={context}>
      {props.children}
    </StoreContext.Provider>
  );
};

export { StoreContext, StoreProvider };
